import { createContext } from 'react';

export type BumperTheme = {
  button?: ButtonTheme;
  colors: {
    primary: string;
    primaryDeep?: string;
    primaryDeeper?: string;
  };
};

export type ButtonTheme = {
  colors: {
    primary: string;
    primaryDeep: string;
  };
};
export interface BumperContextType {
  collapse: () => void;
  expand: () => void;
  expanded: boolean;
  theme: BumperTheme;
}

export const BumperContext = createContext<BumperContextType | undefined>(
  undefined
);
