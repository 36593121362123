import { type ReactNode } from 'react';
import styled from 'styled-components';

import {
  ActionCard,
  type ActionCardProps,
  Icon,
} from '@soluto-private/mx-asurion-ui-react';

import { kebabize } from '@service-initiation/shared-utils-helpers';

const StyledActionCard = styled(ActionCard)`
  width: 240px;
`;

export type ServiceButtonCardProps = Omit<ActionCardProps, 'variant'> & {
  children: ReactNode;
  editable?: boolean;
  iconSrc?: string;
};

export const ServiceButtonCard = ({
  children,
  editable,
  iconSrc,
  ...restProps
}: ServiceButtonCardProps) => (
  <StyledActionCard
    data-testid={`service-card-${children && kebabize(children.toString())}`}
    variant="button"
    {...restProps}
  >
    {iconSrc && (
      <ActionCard.Leading>
        <Icon size="large" src={iconSrc} />
      </ActionCard.Leading>
    )}
    <ActionCard.Heading>{children}</ActionCard.Heading>
    {editable && (
      <ActionCard.Trailing>
        <Icon size="large" src="edit-pencil-change" />
      </ActionCard.Trailing>
    )}
  </StyledActionCard>
);
