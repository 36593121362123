import styled from 'styled-components';

import { Text, colors } from '@soluto-private/mx-asurion-ui-react';

import { SenderOptions, VariantOptions } from './types';

type ChatBubbleContainerProps = {
  isSameSenderAsNext?: boolean;
  sender: string;
  variant: string;
};

const getChatBubbleContainerBorderRadius = ({
  isSameSenderAsNext,
  sender,
  variant,
}: ChatBubbleContainerProps): string => {
  const basedOffVariant = isSameSenderAsNext === undefined;
  if (basedOffVariant) {
    return variant === VariantOptions.ROUND
      ? '24px'
      : sender === SenderOptions.ORIGIN
      ? '24px 24px 24px 0'
      : '24px 24px 0 24px';
  }
  return sender === SenderOptions.ORIGIN && isSameSenderAsNext === false
    ? '24px 24px 24px 0'
    : sender === SenderOptions.USER && isSameSenderAsNext === false
    ? '24px 24px 0 24px'
    : '24px';
};

const ChatBubbleContainer = styled.div<ChatBubbleContainerProps>`
  max-width: 72%;
  padding: 12px 16px;
  border-radius: ${(props) => getChatBubbleContainerBorderRadius(props)};
  background-color: ${({ sender }) =>
    sender === SenderOptions.ORIGIN ? colors.neutralBrightest : `#1967D2`};
  overflow-wrap: break-word;
`;

export interface ChatBubbleProps {
  isSameSenderAsNext?: boolean;
  message: string | unknown;
  sender?: SenderOptions;
  variant?: VariantOptions;
}

export const ChatBubble = ({
  isSameSenderAsNext,
  message,
  sender = SenderOptions.ORIGIN,
  variant = VariantOptions.FLAT,
}: ChatBubbleProps) => {
  return (
    <ChatBubbleContainer
      aria-label="ChatBubble"
      isSameSenderAsNext={isSameSenderAsNext}
      sender={sender}
      variant={variant}
    >
      <Text
        color={sender === SenderOptions.ORIGIN ? colors.black : colors.white}
        weight="base"
      >
        {message}
      </Text>
    </ChatBubbleContainer>
  );
};
