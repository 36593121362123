import styled from 'styled-components';

import { useInViewAnalytics } from '@service-initiation/shared-data-access-analytics';
import { WidgetEventId } from '@service-initiation/widget-data-access-analytics';
import { BUMPER_WIDGET_IFRAME_COLLAPSED_HEIGHT } from '@service-initiation/widget-loader-ui-bumper-widget-breakpoints';

const BodyContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  background-color: white;
  overflow: scroll;

  @media screen and (height: ${BUMPER_WIDGET_IFRAME_COLLAPSED_HEIGHT}px) {
    display: none;
  }
`;

type BodyProps = {
  children: React.ReactNode;
  className?: string;
};

const Body = ({ children, className }: BodyProps) => {
  const { ref: widgetBodyRef } = useInViewAnalytics(WidgetEventId.WidgetBody);
  return (
    <BodyContainer className={className} ref={widgetBodyRef}>
      {children}
    </BodyContainer>
  );
};

export default Body;
