import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { bumperToHostWindowConnection } from '@service-initiation/widget-clients-bumper-to-host-window-connection';
import { BUMPER_WIDGET_IFRAME_MAX_WIDTH } from '@service-initiation/widget-loader-ui-bumper-widget-breakpoints';

import AvatarShufflePlayer from './Bumper.AvatarShufflePlayer';
import Body from './Bumper.Body';
import Header from './Bumper.Header';
import Image from './Bumper.Image';
import Panel from './Bumper.Panel';
import { BumperContext, type BumperTheme } from './BumperContext';
import {
  BUMPER_MAX_HEIGHT,
  BUMPER_MAX_WIDTH,
  BUMPER_MIN_HEIGHT,
  BUMPER_RIGHT_OFFSET,
  BUMPER_TRANSITION_DURATION_IN_MS,
} from './breakpoints';

const AppContainer = styled.main`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BumperContainer = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: calc(100% - ${BUMPER_MIN_HEIGHT}px);
  width: 100%;
  right: 0;
  transition-duration: ${BUMPER_TRANSITION_DURATION_IN_MS}ms;
  transition-property: transform, height;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.24);
  border-radius: 16px 16px 0 0;

  ${({ expanded }) =>
    expanded &&
    css`
      transform: translateY(calc(-100% + ${BUMPER_MIN_HEIGHT}px));

      @media screen and (min-width: ${BUMPER_WIDGET_IFRAME_MAX_WIDTH}px) {
        height: min(100%, ${BUMPER_MAX_HEIGHT}px);
      }
    `}

  @media screen and (min-width: ${BUMPER_WIDGET_IFRAME_MAX_WIDTH}px) {
    right: ${BUMPER_RIGHT_OFFSET}px;
    width: ${BUMPER_MAX_WIDTH}px;
  }
`;

type BumperProps = {
  bumperRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  onCollapse?: () => void;
  onExpand?: () => void;
  theme: BumperTheme;
};

const Bumper = ({
  bumperRef,
  children,
  onCollapse,
  onExpand,
  theme,
}: BumperProps) => {
  const [expanded, setExpanded] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>();

  const clearTimer = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(undefined);
    }
  }, [timerId]);

  const expand = useCallback(() => {
    clearTimer();
    bumperToHostWindowConnection.sendMessage({ type: 'expand-iframe' });
  }, [clearTimer]);

  const collapse = useCallback(() => {
    clearTimer();
    const id = setTimeout(() => {
      bumperToHostWindowConnection.sendMessage({ type: 'collapse-iframe' });
      setTimerId(undefined);
    }, BUMPER_TRANSITION_DURATION_IN_MS);
    setTimerId(id);
    setExpanded(false);
    onCollapse?.();
  }, [clearTimer, onCollapse]);

  const handleIframeExpanded = useCallback(() => {
    setExpanded(true);
    onExpand?.();
  }, [onExpand]);

  useEffect(() => {
    bumperToHostWindowConnection.addListener('expand-bumper', expand);
    bumperToHostWindowConnection.addListener('collapse-bumper', collapse);
    bumperToHostWindowConnection.addListener(
      'iframe-expanded',
      handleIframeExpanded
    );

    return () => {
      bumperToHostWindowConnection.removeListener('expand-bumper', expand);
      bumperToHostWindowConnection.removeListener('collapse-bumper', collapse);
      bumperToHostWindowConnection.removeListener(
        'iframe-expanded',
        handleIframeExpanded
      );
    };
  }, [collapse, expand, handleIframeExpanded]);

  return (
    <BumperContext.Provider
      value={{
        collapse,
        expand,
        expanded,
        theme,
      }}
    >
      <AppContainer>
        <BumperContainer
          data-testid="bumper-container"
          expanded={expanded}
          id="bumper-container"
          ref={bumperRef}
        >
          {children}
        </BumperContainer>
      </AppContainer>
    </BumperContext.Provider>
  );
};

Bumper.AvatarShufflePlayer = AvatarShufflePlayer;
Bumper.Body = Body;
Bumper.Header = Header;
Bumper.Image = Image;
Bumper.Panel = Panel;

export default Bumper;
