import React, { useContext } from 'react';

import { AnalyticsProvider } from '@service-initiation/shared-data-access-analytics';
import { dispatcher } from '@service-initiation/widget-clients-bumper-to-host-analytics-dispatcher';
import {
  InteractionModelContext,
  WidgetComponentType,
} from '@service-initiation/widget-data-access-interaction-model';

type WidgetAnalyticsProviderProps = {
  children: React.ReactNode;
};

export function WidgetAnalyticsProvider({
  children,
}: WidgetAnalyticsProviderProps) {
  const { renderedNodes = [] } = useContext(InteractionModelContext);

  const extraData = {
    history: renderedNodes
      .filter((rn) => rn.type !== WidgetComponentType.MESSAGE)
      .map(({ content, id, partner }) => ({
        content,
        id,
        partner,
      })),
  };

  return (
    <AnalyticsProvider dispatcher={dispatcher} extraData={extraData}>
      {children}
    </AnalyticsProvider>
  );
}

export default WidgetAnalyticsProvider;
