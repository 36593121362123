export enum Partners {
  AMAZON = 'Amazon',
  APPLIANCE_PLUS = 'Appliance Plus',
  ATT = 'AT&T',
  CRICKET = 'Cricket',
  HOME_PLUS = 'Home Plus',
  NONE = 'None',
  NRG = 'NRG',
  UBIF = 'uBreakiFix',
  VERIZON = 'Verizon',
}
