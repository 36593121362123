import {
  Channel,
  type ConfigState,
  Language,
  MessagingTopic,
  Partner,
} from '@soluto-private/remote-expert-types';

import { ExpertSDK } from '@service-initiation/shared-clients-expert-sdk';

export const expertSDK = new ExpertSDK('remote-expert-container', {
  config: {
    appName: 'Service Router',
    channel: Channel.CHAT,
    environment: process.env.NX_PUBLIC_APP_ENV || 'development',
    language: Language.EN_US,
    messagingTopic: MessagingTopic.DEVICE_REPLACEMENT_ONBOARDING,
    partner: Partner.Verizon,
    registerToEvents: (e: unknown) => {
      console.log(e);
    },
  } as ConfigState,
  environment: process.env.NX_PUBLIC_APP_ENV || 'development',
  id: 'service-router-vzw',
  version: 'v1',
});
