import {
  attachSDKToContainer,
  detachSDKFromContainer,
  hideChatBubble,
  initialize,
  injectChatScriptByVersion,
  sendMessage,
} from '@soluto-private/remote-expert-sdk';
import { type ConfigState } from '@soluto-private/remote-expert-types';

interface AttachOptions {
  autoSend?: boolean;
  firstMessage?: string;
}

interface ExpertSDKConfig {
  config: ConfigState;
  environment: string;
  id: string;
  version: 'v1';
}

export class ExpertSDK {
  private containerId: string;
  private expertSDKConfig: ExpertSDKConfig;

  constructor(containerId: string, config: ExpertSDKConfig) {
    this.containerId = containerId;
    this.expertSDKConfig = config;
  }

  private async hideChatBubble() {
    await hideChatBubble();
  }

  private async injectChatScript() {
    const { config, environment, id, version } = this.expertSDKConfig;
    await injectChatScriptByVersion(
      {
        environment: environment as 'development' | 'production',
        id: id,
        version: version,
      },
      async () => {
        await initialize(config);
      }
    );
  }

  attachSDK(options: AttachOptions) {
    attachSDKToContainer(this.containerId);
    if (options.firstMessage) {
      this.sendMessage(options.firstMessage, options.autoSend);
    }
  }

  async detachSDK() {
    detachSDKFromContainer();
    await this.hideChatBubble();
  }

  async initializeSDK() {
    await this.injectChatScript();
  }

  sendMessage(message: string, isUserInitiated = false) {
    sendMessage(message, isUserInitiated);
  }
}
