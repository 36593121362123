import React, { useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { expertSDK } from '@service-initiation/widget-verizon-clients-expert-sdk';

import { Actions } from '../types';

type ExpertSDKActionsContextType = {
  actionsHandler: (action: Actions) => void;
  activeChat?: boolean;
  mounting?: boolean;
};

export const ExpertSDKActionsContext =
  React.createContext<ExpertSDKActionsContextType>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    actionsHandler: () => {},
  });

export const ExpertSDKActionsProvider = ({
  children,
  messageContent,
}: {
  children: React.ReactNode;
  messageContent?: string;
}) => {
  const [activeChat, setActiveChat] = useState(false);

  const [{ loading }, handleMountExpertSDK] = useAsyncFn(async () => {
    setActiveChat(true);
    await expertSDK.initializeSDK();
    expertSDK.attachSDK({
      autoSend: true,
      firstMessage: messageContent,
    });
  }, [messageContent]);

  const handleUnmountExpertSDK = useCallback(async () => {
    setActiveChat(false);
    await expertSDK.detachSDK();
  }, []);

  const actionsHandler = useCallback(
    async (action: Actions) => {
      switch (action) {
        case Actions.LaunchExpertSDK:
          handleMountExpertSDK();
          break;
        case Actions.CloseExpertSDK:
          await handleUnmountExpertSDK();
          break;
        default:
          break;
      }
    },
    [handleMountExpertSDK, handleUnmountExpertSDK]
  );
  return (
    <ExpertSDKActionsContext.Provider
      value={{ actionsHandler, activeChat: activeChat, mounting: loading }}
    >
      {children}
    </ExpertSDKActionsContext.Provider>
  );
};
