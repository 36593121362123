import { createGlobalStyle } from 'styled-components';

export const GlobalVendorScrollbarResetRule = createGlobalStyle`
  div {
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }

  ul {
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }

  div::-webkit-scrollbar {
    display: none
  }

  ul::-webkit-scrollbar {
    display: none
  }
`;
