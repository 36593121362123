import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { useAnalytics } from './useAnalytics';

export type InViewAnalyticsProps = {
  extraData?: Record<string, unknown>;
  name: string;
};

export const useInViewAnalytics = (
  id: string,
  extraData?: Record<string, unknown>,
  identities: Record<string, unknown> = {}
) => {
  const viewAnalyticsSent = useRef(false);
  const { dispatchViewEvent } = useAnalytics();
  const { inView, ref } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (!viewAnalyticsSent.current && inView) {
      viewAnalyticsSent.current = true;
      dispatchViewEvent(id, extraData, identities);
    }
  }, [dispatchViewEvent, extraData, inView, id, identities]);

  return { ref };
};
