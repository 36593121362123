import styled from 'styled-components';

import { BUMPER_TRANSITION_DURATION_IN_MS } from './breakpoints';
import { useBumperContext } from './hooks';

const Img = styled.img<{ expanded: boolean }>`
  width: ${({ expanded }) => (expanded ? '48px' : '40px')};
  height: auto;
  transition-duration: ${BUMPER_TRANSITION_DURATION_IN_MS}ms;
  transition-property: 'height,width';
  transition-timing-function: 'ease-in-out';
`;

type ImageProps = {
  alt: string;
  className?: string;
  src: string;
};

const Image = ({ alt, className, src }: ImageProps) => {
  const { expanded } = useBumperContext();

  return <Img alt={alt} className={className} expanded={expanded} src={src} />;
};

export default Image;
