import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';

import { InteractionModelContext } from '@service-initiation/widget-data-access-interaction-model';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const AnimatedWrapper = styled.div<{
  delay: number;
  isAdvancing: boolean;
  speed: number;
}>`
  opacity: ${({ isAdvancing }) => (isAdvancing ? 0 : 100)};
  overflow: auto;
  min-height: 0px;
  animation: ${({ isAdvancing }) => (isAdvancing ? slideIn : slideOut)}
    ${({ speed }) => speed}s ease-out ${({ delay }) => delay}ms forwards;
`;

interface SlideInAnimationWrapperProps {
  delay?: number;
  isAdvancing?: boolean;
  speed?: number;
}

export const SlideInAnimationWrapper: React.FC<
  SlideInAnimationWrapperProps
> = ({ children, delay = 400, isAdvancing = true, speed = 0.4 }) => {
  const { onAnimationDone } = useContext(InteractionModelContext);
  return (
    <AnimatedWrapper
      delay={delay}
      isAdvancing={isAdvancing}
      onAnimationEnd={
        isAdvancing ? undefined : () => onAnimationDone('wrapper')
      }
      speed={speed}
    >
      {children}
    </AnimatedWrapper>
  );
};
