import { type InteractionNode } from '../../types';

export const searchInteractionModelRecursivelyForParentNode = (
  parentId: number,
  interactionModel: InteractionNode[]
) => {
  const search = (
    nodes: InteractionNode[],
    parentId: number
  ): InteractionNode | undefined => {
    for (const node of nodes) {
      if (node.id === parentId) {
        return node;
      }
      if (node.children) {
        const result = search(node.children, parentId);
        if (result) {
          return result;
        }
      }
    }
  };
  return search(interactionModel, parentId);
};
