import { Analytics } from 'analytics';
import { type AnalyticsEventModel } from 'shisell';

import { hostPlugin } from './plugins';

const analytics = Analytics({
  app: 'widget',
  plugins: [hostPlugin],
});

export const writer = (eventModel: AnalyticsEventModel) => {
  analytics.track(eventModel.Name, eventModel);
};
