import { type AnalyticsPlugin } from 'analytics';

import { type AnalyticsEvent } from '@service-initiation/shared-clients-analytics-dispatcher';
import { bumperToHostWindowConnection } from '@service-initiation/widget-clients-bumper-to-host-window-connection';

export const hostPlugin: AnalyticsPlugin = {
  name: 'host',
  track: (event: AnalyticsEvent) => {
    event.payload.properties.MetaData.DispatchedBy = 'SIN-HostPlugin';
    bumperToHostWindowConnection.sendMessage({
      payload: event.payload.properties,
      type: 'analytic',
    });
  },
};
