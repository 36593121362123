import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  type InteractionModelContextType,
  type InteractionNode,
  NodeLastAction,
  WidgetComponentType,
} from '../types';
import { searchInteractionModelRecursivelyForParentNode } from './utils';

export const InteractionModelContext =
  React.createContext<InteractionModelContextType>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAnimationDone: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onNodeClicked: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    selectNodeById: (id: number) => {},
  });

export type InteractionModelProviderProps = {
  children: React.ReactNode;
  model: InteractionNode[];
};

export const InteractionModelProvider = ({
  children,
  model,
}: InteractionModelProviderProps) => {
  const [interactionModel, setInteractionModel] =
    useState<InteractionNode[]>(model);

  const [renderedNodes, setRenderedNodes] = useState<InteractionNode[]>();

  const [activeNodeIndices, setActiveNodeIndices] = useState<number[]>([]);

  const [selectionNodes, setSelectionNodes] = useState<{
    lastAction: NodeLastAction;
    nodes: InteractionNode[];
  }>({ lastAction: NodeLastAction.Advance, nodes: interactionModel });

  const contentSnippets = useMemo(() => {
    const contentSnippets =
      activeNodeIndices &&
      activeNodeIndices.map((id) => {
        const node = searchInteractionModelRecursivelyForParentNode(
          id,
          interactionModel ?? []
        );
        return node?.content;
      });

    return contentSnippets?.join(', ') ?? '';
  }, [activeNodeIndices, interactionModel]);

  const [lastClickedNode, setLastClickedNode] = useState<InteractionNode>();

  const animationState = useRef<{
    needsContainer: boolean;
    needsWrapper: boolean;
  }>({ needsContainer: false, needsWrapper: false });

  const selectNode = useCallback(
    (node?: InteractionNode) => {
      if (!node) return;
      if (!activeNodeIndices.includes(node.id)) {
        setRenderedNodes((prev) => {
          if (!prev)
            return selectionNodes.nodes.filter(
              (n) =>
                n.id === node.id ||
                n.type === WidgetComponentType.MESSAGE ||
                activeNodeIndices.includes(n.id)
            );
          else {
            return [
              ...prev.filter(
                (n) =>
                  n.id === node.id ||
                  n.type === WidgetComponentType.MESSAGE ||
                  activeNodeIndices.includes(n.id)
              ),
              ...selectionNodes.nodes.filter(
                (n) =>
                  n.id === node.id || n.type === WidgetComponentType.MESSAGE
              ),
            ];
          }
        });

        setSelectionNodes({
          lastAction: NodeLastAction.Advance,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          nodes: node.children!,
        });

        setActiveNodeIndices((prev) => [...prev, node.id]);
      } else {
        const parentId =
          activeNodeIndices[
            activeNodeIndices.findIndex((id) => id === node.id) - 1
          ];

        const renderedNodesIndexOfParentId = renderedNodes?.findIndex(
          (n) => n.id === parentId
        );

        const parentNode = searchInteractionModelRecursivelyForParentNode(
          parentId,
          interactionModel
        );

        setActiveNodeIndices(
          activeNodeIndices.slice(0, activeNodeIndices.indexOf(node.id))
        );

        if (parentId > 0) {
          setRenderedNodes((prev) => {
            return [
              ...(prev?.slice(
                0,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                renderedNodesIndexOfParentId! + 1
              ) as InteractionNode[]),
              ...(parentNode?.children?.filter(
                (n) => n.type === WidgetComponentType.MESSAGE
              ) as InteractionNode[]),
            ];
          });
          setSelectionNodes({
            lastAction: NodeLastAction.Advance,
            nodes: parentNode?.children?.filter(
              (n) =>
                n.type === WidgetComponentType.SERVICE ||
                n.type === WidgetComponentType.PARTNER
            ) as InteractionNode[],
          });
        } else {
          setRenderedNodes(
            interactionModel.filter(
              (n) => n.type === WidgetComponentType.MESSAGE
            ) as InteractionNode[]
          );
          setSelectionNodes({
            lastAction: NodeLastAction.Advance,
            nodes: interactionModel.filter(
              (n) =>
                n.type === WidgetComponentType.SERVICE ||
                n.type === WidgetComponentType.PARTNER
            ) as InteractionNode[],
          });
        }
      }
    },
    [activeNodeIndices, interactionModel, renderedNodes, selectionNodes.nodes]
  );

  const onAnimationDone = useCallback(
    (component: 'container' | 'wrapper') => {
      if (
        (animationState.current.needsContainer &&
          !animationState.current.needsWrapper &&
          component === 'wrapper') ||
        (animationState.current.needsWrapper &&
          !animationState.current.needsContainer &&
          component === 'container')
      ) {
        animationState.current = {
          needsContainer:
            component === 'container'
              ? false
              : animationState.current.needsContainer,
          needsWrapper:
            component === 'wrapper'
              ? false
              : animationState.current.needsWrapper,
        };
        return;
      }

      selectNode(lastClickedNode);
    },
    [lastClickedNode, selectNode]
  );

  const selectNodeById = useCallback(
    (id: number) => {
      if (activeNodeIndices.length > 0) return;
      const node = interactionModel.find((n) => n.id === id);
      selectNode(node);
    },
    [activeNodeIndices, interactionModel, selectNode]
  );

  const onNodeClicked = useCallback((node: InteractionNode) => {
    if (!node.children) return;
    setLastClickedNode(node);
    animationState.current = {
      needsContainer: true,
      needsWrapper: true,
    };
    setSelectionNodes((prev) => ({
      lastAction: NodeLastAction.Retreat,
      nodes: prev.nodes,
    }));
  }, []);

  const value = useMemo(() => {
    return {
      activeNodeIndices,
      contentSnippets,
      interactionModel,
      onAnimationDone,
      onNodeClicked,
      renderedNodes,
      selectNodeById,
      selectionNodes,
      setInteractionModel,
    };
  }, [
    activeNodeIndices,
    interactionModel,
    contentSnippets,
    onNodeClicked,
    renderedNodes,
    selectionNodes,
    onAnimationDone,
    setInteractionModel,
    selectNodeById,
  ]);

  return (
    <InteractionModelContext.Provider value={value}>
      {children}
    </InteractionModelContext.Provider>
  );
};
