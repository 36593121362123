import { useContext } from 'react';

import { BumperContext, type BumperContextType } from '../BumperContext';

export const useBumperContext = (): BumperContextType => {
  const context = useContext(BumperContext);
  if (!context) {
    throw new Error('useBumperContext can only be used within the Bumper!');
  }
  return context;
};
