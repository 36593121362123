import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { useOnScreen } from '@service-initiation/shared-utils-hooks';
import {
  InteractionModelContext,
  NodeLastAction,
  WidgetComponentType,
} from '@service-initiation/widget-data-access-interaction-model';
import { SlideInAnimationWrapper } from '@service-initiation/widget-ui-slide-in-animation-wrapper';

import { WidgetComponent } from './WidgetComponent';

const WidgetTreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const growHeight = (height?: number) => keyframes`
  0% {
    height: 0;
  }
  100% {
    height: ${height}px;
  }
`;

const shrinkHeight = (height?: number) => keyframes`
  0% {
    height: ${height}px;
  }
  100% {
    height: 0;
  }
`;

const GrowHeightContainer = styled.div<{
  height?: number;
  isAdvancing: boolean;
}>`
  overflow: hidden;
  height: ${({ height, isAdvancing }) =>
    isAdvancing ? '0px' : (height || 0) + 'px'};
  animation: ${({ height, isAdvancing }) =>
      isAdvancing ? growHeight(height) : shrinkHeight(height)}
    400ms ease-in-out 300ms forwards;
`;

const InnerContainer = styled.div``;

const MESSAGE_SLIDE_DELAY = 400;
const SERVICE_SLIDE_DELAY = 700;
const HEIGHT_SKEW = 1.001;

export const WidgetComponentView = () => {
  const { onAnimationDone, renderedNodes, selectionNodes } = useContext(
    InteractionModelContext
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(containerRef);
  const [height, setHeight] = useState<number>();
  const [finalSelectionComponents, setFinalSelectionComponents] =
    useState<JSX.Element[]>();

  useEffect(() => {
    if (containerRef.current && isVisible) {
      containerRef.current.offsetHeight === height
        ? setHeight(containerRef.current.offsetHeight * HEIGHT_SKEW)
        : setHeight(containerRef.current.offsetHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, renderedNodes]);

  useMemo(() => {
    const finalComponentGroups: JSX.Element[] = [];
    let componentGroups: JSX.Element[] = [];
    let currentGroupType = selectionNodes?.nodes?.[0].type;

    selectionNodes?.nodes?.forEach((node, idx) => {
      if (node.type === currentGroupType) {
        componentGroups.push(<WidgetComponent key={idx} node={node} />);
      } else {
        finalComponentGroups.push(
          <SlideInAnimationWrapper
            delay={
              currentGroupType === WidgetComponentType.MESSAGE
                ? MESSAGE_SLIDE_DELAY
                : SERVICE_SLIDE_DELAY
            }
            isAdvancing={selectionNodes.lastAction === NodeLastAction.Advance}
            key={
              idx === selectionNodes.nodes.length - 1 ? node.id + 1 : node.id
            }
          >
            {componentGroups.map((group) => group)}
          </SlideInAnimationWrapper>
        );
        componentGroups = [];
        currentGroupType = node.type;
        componentGroups.push(<WidgetComponent key={idx} node={node} />);
      }
      if (
        idx === selectionNodes.nodes.length - 1 &&
        componentGroups.length > 0
      ) {
        finalComponentGroups.push(
          <SlideInAnimationWrapper
            delay={
              currentGroupType === WidgetComponentType.MESSAGE
                ? MESSAGE_SLIDE_DELAY
                : SERVICE_SLIDE_DELAY
            }
            isAdvancing={selectionNodes.lastAction === NodeLastAction.Advance}
            key={node.id}
          >
            {componentGroups.map((group) => group)}
          </SlideInAnimationWrapper>
        );
      }
    });

    setFinalSelectionComponents(finalComponentGroups);
  }, [selectionNodes]);

  return (
    <WidgetTreeContainer>
      {renderedNodes &&
        renderedNodes?.map((node, idx) => (
          <WidgetComponent key={idx} node={node} />
        ))}
      <GrowHeightContainer
        height={height}
        isAdvancing={selectionNodes?.lastAction === NodeLastAction.Advance}
        onAnimationEnd={
          selectionNodes?.lastAction === NodeLastAction.Advance
            ? undefined
            : () => onAnimationDone('container')
        }
      >
        <InnerContainer ref={containerRef}>
          {finalSelectionComponents?.map((group) => group)}
        </InnerContainer>
      </GrowHeightContainer>
    </WidgetTreeContainer>
  );
};
