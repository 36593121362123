import { type Listener, type Message, type WindowConnection } from './types';

export type CreateWindowConnectionOptions = {
  allowedTargetOrigins?: string[];
  originWindow?: Window;
  targetOrigin?: string;
  targetWindow: Window | null;
};

export const createWindowConnection = <
  OriginMessage extends Message<string, unknown>,
  TargetMessage extends Message<string, unknown>
>({
  allowedTargetOrigins,
  originWindow = window,
  targetOrigin = '*',
  targetWindow,
}: CreateWindowConnectionOptions): WindowConnection<
  OriginMessage,
  TargetMessage
> => {
  const listeners: Record<string, Listener<unknown>[]> = {};

  const onMessageEventListener = (event: MessageEvent) => {
    if (allowedTargetOrigins && !allowedTargetOrigins.includes(event.origin)) {
      return;
    }
    const message = event.data as TargetMessage;
    (listeners[message.type] || []).forEach((listener) =>
      listener(message.payload)
    );
  };

  originWindow.addEventListener('message', onMessageEventListener);

  return {
    addListener: (type, listener) => {
      listeners[type] = [...(listeners[type] || []), listener];
    },
    destroy: () => {
      originWindow.removeEventListener('message', onMessageEventListener);
    },
    removeListener: (type, listener) => {
      listeners[type] = (listeners[type] || []).filter(
        (existingListener) => existingListener !== listener
      );
    },
    sendMessage: (message) => {
      targetWindow?.postMessage(message, targetOrigin);
    },
  };
};
