import {
  DotLottiePlayer,
  type Props as DotLottiePlayerProps,
} from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

import { BUMPER_TRANSITION_DURATION_IN_MS } from './breakpoints';
import { useBumperContext } from './hooks';

const AvatarShufflePlayer = ({ src }: DotLottiePlayerProps) => {
  const { expanded } = useBumperContext();

  return (
    <DotLottiePlayer
      autoplay
      src={src}
      style={{
        flex: '0 0 auto',
        height: expanded ? '48px' : '40px',
        transitionDuration: `${BUMPER_TRANSITION_DURATION_IN_MS}ms`,
        transitionProperty: 'height,width',
        transitionTimingFunction: 'ease-in-out',
        width: expanded ? '48px' : '40px',
      }}
    />
  );
};

export default AvatarShufflePlayer;
