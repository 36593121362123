import { useEffect } from 'react';

export enum AuiToken {
  AsurionDark = 'asurion-dark',
  AsurionLight = 'asurion-light',
  PartnerDark = 'partner-dark',
  PartnerLight = 'partner-light',
}

export const useApplyAuiTokenClass = (
  htmlElement: HTMLElement,
  tokenClass: AuiToken
) => {
  useEffect(() => {
    const auiTokenValues = Object.values(AuiToken);

    for (const value of auiTokenValues) {
      if (htmlElement.classList.contains(value)) {
        htmlElement.classList.remove(value);
      }
    }

    htmlElement.classList.add(tokenClass);

    return () => {
      htmlElement.classList.remove(tokenClass);
    };
  }, [htmlElement.classList, tokenClass]);
};
