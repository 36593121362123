import styled, { css } from 'styled-components';

import { colors } from '@soluto-private/mx-asurion-ui-react';

import {
  useAnalytics,
  useInViewAnalytics,
} from '@service-initiation/shared-data-access-analytics';
import { WidgetEventId } from '@service-initiation/widget-data-access-analytics';

import {
  BUMPER_MIN_HEIGHT,
  BUMPER_TRANSITION_DURATION_IN_MS,
} from './breakpoints';
import { useBumperContext } from './hooks';

export type HeaderContainerProps = {
  color: string;
  expanded: boolean;
};

const HeaderContainer = styled.button<HeaderContainerProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: ${BUMPER_MIN_HEIGHT}px;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 16px 16px 0 0;
  background: ${(props) => props.color};
  width: 100%;
  border-style: none;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  gap: 12px;
  align-items: center;
  color: ${colors.white};
  text-align: left;
  text-wrap: balance;
  transition-timing-function: ease-in-out;
  transition: min-height ${BUMPER_TRANSITION_DURATION_IN_MS}ms,
    padding ${BUMPER_TRANSITION_DURATION_IN_MS}ms,
    gap ${BUMPER_TRANSITION_DURATION_IN_MS}ms,
    align-items ${BUMPER_TRANSITION_DURATION_IN_MS}ms;
  z-index: 10;

  ${({ expanded }) =>
    expanded &&
    css`
      min-height: auto;
      padding: 24px 16px;
      gap: 16px;
      align-items: flex-start;
    `}
`;

type HeaderProps = {
  children: React.ReactNode;
  className?: string;
};

const Header = ({ children, className }: HeaderProps) => {
  const { collapse, expand, expanded, theme } = useBumperContext();
  const { ref: widgetHeaderRef } = useInViewAnalytics(
    WidgetEventId.WidgetHeader
  );

  const { dispatchClickEvent } = useAnalytics();

  const handleClick = () => {
    dispatchClickEvent(WidgetEventId.WidgetHeader, {
      Action: expanded ? 'closed' : 'opened',
    });
    expanded ? collapse() : expand();
  };

  return (
    <HeaderContainer
      className={className}
      color={theme.colors.primary}
      data-testid="bumper-header"
      expanded={expanded}
      onClick={handleClick}
      ref={widgetHeaderRef}
    >
      {children}
    </HeaderContainer>
  );
};

export default Header;
