import { createContext, useCallback } from 'react';

import {
  type AnalyticsDispatcher,
  AnalyticsEventName,
} from '@service-initiation/shared-clients-analytics-dispatcher';

export type AlreadyIncludedExtraData = { id?: never };

export interface AnalyticsContextValue {
  dispatchClickEvent: (
    id: string,
    extraData?: Record<string, unknown> & AlreadyIncludedExtraData
  ) => void;
  dispatchViewEvent: (
    id: string,
    extraData?: Record<string, unknown> & AlreadyIncludedExtraData,
    identities?: Record<string, unknown>
  ) => void;
}

export const AnalyticsContext = createContext<
  AnalyticsContextValue | undefined
>(undefined);

export type AnalyticsProviderProps = {
  children: React.ReactNode;
  dispatcher: AnalyticsDispatcher;
  extraData?: Record<string, unknown>;
};

export const AnalyticsProvider = ({
  children,
  dispatcher,
  extraData: globalExtraData = {},
}: AnalyticsProviderProps) => {
  const dispatchEvent = useCallback(
    (
      event: AnalyticsEventName,
      extraData: Record<string, unknown> = {},
      identities: Record<string, unknown> = {}
    ) => {
      dispatcher.dispatch(event, {
        ExtraData: { ...globalExtraData, ...extraData },
        Identities: { ...identities },
      });
    },
    [dispatcher, globalExtraData]
  );

  const dispatchViewEvent = useCallback(
    (
      id: string,
      extraData: Record<string, unknown> = {},
      identities: Record<string, unknown> = {}
    ) => {
      dispatchEvent(AnalyticsEventName.View, { ...extraData, id }, identities);
    },
    [dispatchEvent]
  );

  const dispatchClickEvent = useCallback(
    (id: string, extraData: Record<string, unknown> = {}) => {
      dispatchEvent(AnalyticsEventName.Click, { ...extraData, id });
    },
    [dispatchEvent]
  );

  return (
    <AnalyticsContext.Provider
      value={{
        dispatchClickEvent,
        dispatchViewEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
