import {
  type CreateWindowConnectionOptions,
  type WindowConnection,
  createWindowConnection as baseCreateWindowConnection,
} from '@service-initiation/shared-clients-window-connection';

import {
  type BumperMessage,
  type ExpertChatMessage,
  type HostMessage,
  type InteractionModelMessage,
  type SimplrChatMessage,
} from './types';

export type BumperToHostWindowConnection = WindowConnection<
  | BumperMessage
  | ExpertChatMessage
  | InteractionModelMessage
  | SimplrChatMessage,
  HostMessage
>;

export const createBumperToHostWindowConnection = (
  props: CreateWindowConnectionOptions
): BumperToHostWindowConnection =>
  baseCreateWindowConnection<
    | BumperMessage
    | ExpertChatMessage
    | InteractionModelMessage
    | SimplrChatMessage,
    HostMessage
  >(props);
