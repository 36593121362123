import styled from 'styled-components';

import {
  ActionCard,
  type ActionCardProps,
  Icon,
  Text,
} from '@soluto-private/mx-asurion-ui-react';

import { kebabize } from '@service-initiation/shared-utils-helpers';
import { Partners } from '@service-initiation/widget-data-access-partners';

export type PartnerButtonCardProps = Omit<ActionCardProps, 'variant'> & {
  editable?: boolean;
  partner: Partners;
};

const PartnerLogoSrcFiles: Record<Partners, string> = {
  [Partners.AMAZON]: 'amazon-logo-standalone.svg',
  [Partners.APPLIANCE_PLUS]: 'appliance-plus-logo-standalone.svg',
  [Partners.ATT]: 'att-logo-standalone.svg',
  [Partners.CRICKET]: 'cricket-logo-standalone.svg',
  [Partners.HOME_PLUS]: 'asurion-home-plus-logo.svg',
  [Partners.NONE]: "I don't have a protection plan",
  [Partners.NRG]: 'nrg-logo-standalone.svg',
  [Partners.UBIF]: 'ubreakifix-logo-standalone.svg',
  [Partners.VERIZON]: 'verizon-logo-standalone.svg',
};

const AUI_LOGO_CDN = 'https://assets.asurion-ui.my.asurion53.com/images/logos';

const getPartnerLogoSrc = (partner: Partners) =>
  `${AUI_LOGO_CDN}/${PartnerLogoSrcFiles[partner]}`;

const StyledActionCard = styled(ActionCard)`
  width: 240px;
`;

export const PartnerButtonCard = ({
  editable,
  partner,
  ...restProps
}: PartnerButtonCardProps) => (
  <StyledActionCard
    variant="button"
    {...restProps}
    data-testid={`partner-card-${partner && kebabize(partner.toString())}`}
  >
    {!(partner === Partners.NONE) ? (
      <ActionCard.Leading>
        <img
          alt={`${partner} logo`}
          height="36px"
          src={getPartnerLogoSrc(partner)}
        />
      </ActionCard.Leading>
    ) : (
      <ActionCard.Heading>
        <Text weight="base">{PartnerLogoSrcFiles[partner]}</Text>
      </ActionCard.Heading>
    )}
    {editable && (
      <ActionCard.Trailing>
        <Icon size="large" src="edit-pencil-change" />
      </ActionCard.Trailing>
    )}
  </StyledActionCard>
);
