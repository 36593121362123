import { useContext } from 'react';
import styled from 'styled-components';

import { useAnalytics } from '@service-initiation/shared-data-access-analytics';
import { WidgetEventId } from '@service-initiation/widget-data-access-analytics';
import {
  InteractionModelContext,
  type InteractionNode,
  WidgetComponentType,
} from '@service-initiation/widget-data-access-interaction-model';
import { type Partners } from '@service-initiation/widget-data-access-partners';
import {
  ChatBubble,
  SenderOptions,
} from '@service-initiation/widget-ui-chat-bubble';
import { PartnerButtonCard } from '@service-initiation/widget-ui-partner-button-card';
import { ServiceButtonCard } from '@service-initiation/widget-ui-service-button-card';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ExpertSDKActionsContext } from '@service-initiation/widget-verizon-data-access-expert-sdk';

type WidgetComponentProps = {
  node: InteractionNode;
};

const ChatRow = styled.div<{ sender: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ sender }) =>
    sender === SenderOptions.ORIGIN ? 'flex-start' : 'flex-end'};
  margin: 4px 0px;
`;

export const WidgetComponent = ({ node }: WidgetComponentProps) => {
  const {
    actions,
    children,
    content,
    dataAnalyticsId,
    href,
    iconSrc,
    id,
    messageVariant,
    partner,
    phone,
    type,
  } = node;
  const { activeNodeIndices, onNodeClicked } = useContext(
    InteractionModelContext
  );

  const { actionsHandler } = useContext(ExpertSDKActionsContext);
  const { dispatchClickEvent } = useAnalytics();
  const extraProps = {
    content,
    ...(dataAnalyticsId ? { 'data-analytics-id': dataAnalyticsId } : {}),
    hasChildren: !!children,
    hasHref: !!href,
    hasPhone: !!phone,
    nodeId: id,
    partner,
    type,
  };

  switch (type) {
    case WidgetComponentType.MESSAGE:
      return (
        <ChatRow sender={SenderOptions.ORIGIN}>
          <ChatBubble message={content} variant={messageVariant} />
        </ChatRow>
      );
    case WidgetComponentType.PARTNER:
      return (
        <ChatRow sender={SenderOptions.USER}>
          <PartnerButtonCard
            editable={activeNodeIndices?.includes(id)}
            onClick={() => {
              dispatchClickEvent(
                WidgetEventId.ChatWindow_PartnerButtonCard,
                extraProps
              );
              onNodeClicked(node);
            }}
            partner={partner as Partners}
          />
        </ChatRow>
      );
    default:
      return (
        <ChatRow sender={SenderOptions.USER}>
          <ServiceButtonCard
            editable={activeNodeIndices?.includes(id)}
            iconSrc={iconSrc}
            onClick={() => {
              dispatchClickEvent(
                WidgetEventId.ChatWindow_ServiceButtonCard,
                extraProps
              );
              if (href) {
                window.open(href);
              } else if (phone) {
                window.open(`tel:${phone}`);
              } else if (actions) {
                actionsHandler(actions);
              } else {
                onNodeClicked(node);
              }
            }}
          >
            {content}
          </ServiceButtonCard>
        </ChatRow>
      );
  }
};
